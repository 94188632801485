<template>
  <v-card height="570">
    <iframe :src="url" 
    frameborder="0"  
    class="responsive-iframe" 
    allowfullscreen></iframe>
  </v-card>
</template>

<script>
export default {
  props: ['url']
}
</script>
